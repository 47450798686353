import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "אפיון תוכנה הוא שלב מרכזי בפיתוח אפליקציות ומערכות ווב, יש להקדיש זמן ומאמץ כדי להגדיר נכון את מהות התוכנה ורכיביה, וכל פרויקט בעולם הפיתוח מתחיל במסמך אפיון. למסמך האפיון יש ארבע מטרות מרכזיות:"
    ],
    ul: [
      "אפיון התוכנה ויצירת הסכמה בארגון לגבי מטרות התוכנה, מהותה והיקפה.",
      "בסיס למפרט טכני המהווה שלד, סקיצות וסכמה לצוות הפיתוח ולמומחי עיצוב הממשקים למשתמשים, לרבות בחירת טכנולוגיות לפיתוח המערכת.",
      "בסיס לפיתוח, לבניית, לעיצוב ולבדיקת התוכנה עם אמצעי בקרה לשלבים השונים.",
      "אפיון קהל יעד ומסרים שיווקיים.",
    ]
  },
  section2: {
    p: [
      "חשוב להדגיש כי שלב האפיון הוא שלב קריטי ובעל השפעה מכרעת על תהליך הפיתוח ובניית המערכת (תוכנה או אפליקציה). אם מכינים מסמך אפיון איכותי ומפורט, פרויקט הפיתוח יצליח היטב והלקוח יקבל תמורה מלאה לכספו, והתוכנה תשרת היטב את לקוחות הקצה. את מסמך האפיון מכין צוות מיומן של בית התוכנה, כאשר הצוות אחראי גם על פיתוח המוצר הטכנולוגי ועל פיתוח ממשקים ידידותיים למשתמשים."
    ],
  },
  section3: {
    h: "שלב קריטי והכרחי בפיתוח תוכנה",
    p: [
      <>
        {`בית התוכנה אפספורס מתמחה ב`}
        <Link to={URL.FRONT_END_SERVICE} active>פיתוח פרונט אנד (Front End Development)</Link>,
        {` `}
        <Link to={URL.FULL_STACK_SERVICE} active>פול סטאק (Full Stack development)</Link>,
        {` `}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>אפליקציות SaaS</Link>
        {`ו `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>ואפיון ועיצוב UI/UX</Link>.
        {` כל פרויקט לפיתוח תוכנה מתחיל אצלנו באפיון המוצר ורכיביו, זהו שלב קריטי ומרכזי בפיתוח אפליקציות, אתרים ומערכות WEB מתקדמות. כדי להבין את חשיבות מסמך האפיון ניתן להקביל את המקרה לענף הבנייה, כלומר אם התוצר הסופי שאנו מעוניינים לקבל הוא בניין מגורים מודרני, מסמך האפיון כולל את תוכניות העבודה להקמת המבנה, תוך התייחסות לפרמטרים חשובים כמו תקציב, לוח זמנים, חומרים, תשתיות, עיצוב הבניין ועוד. `}
      </>,
      "בתחום הפיתוח, מסמך אפיון מהווה מעין חוזה עבודה בין שני הצדדים, כלומר בין בית התוכנה וצוות הפיתוח לבין הלקוח, המסמך משמש בין השאר לתיאום ציפיות, לכן הוא כולל התייחסות למפרט התוכנה והמערכת, דרישות מיוחדות של הלקוח, תוכנית עבודה עקרונית לצורך פיתוח המערכת, תרשים זרימת נתונים, וכמובן תיאור המצב הקיים ובעיות שהתוכנה אמורה לפתור."
    ]
  },
  section4: {
    h: "הכנת מסך אפיון לפיתוח תוכנה – שלב אחר שלב",
    p: [
      "אפיון תוכנה נחוץ ללקוח הסופי ולצוות הפיתוח של בית התוכנה. הלקוח מגיע עם רעיון לתוכנה או לאפליקציה חדשה, וצוות הפיתוח נדרש לבדוק את הרעיון ולהפוך אותו למוצר אמיתי. אפיון המערכת כולל תיאור כללי, מחקר שוק ומתחרים, הגדרת מטרות, קהל יעד, אופי וצביון של המערכת, בחירת טכנולוגיות לפיתוח התוכנה, לוח זמנים, שרטוטים ותרשימי זרימה, עיצוב כללי של הממשקים, היררכיה, פיצ'רים ובנוסף יש להתייחס להיבטי השיווק והמסר העיקרי המשתלב בתוכנה.",
      "אפיון המוצר הטכנולוגי, במקרה שלנו תוכנה או אפליקציה, מאפשר לצוות בית התוכנה לחלק משימות לכל המעורבים בפיתוח המערכת, לקבוע זמנים ולתכנן מערכת בקרה אפקטיבית על מנת לעמוד בזמנים ובתקציב, ולשחרר תוכנה מתקדמת טכנולוגית ונוחה למשתמשים המבטיחה תמורה מלאה ללקוח של חברת התוכנה.",
      "למעשה, מסמך האפיון מורכב מארבעה חלקים עיקריים:"
    ],
    ol: [
      <>
        <strong>1. מחקר ומטרות</strong>
        {` – מחקר הוא חלק חשוב באפיון תוכנה, הוא מאפשר לבדוק טכנולוגיות קיימות, פתרונות של מתחרים, צרכים של לקוחות פוטנציאליים ועוד. ידע הוא כוח לכן אין לוותר על מחקר שוק, ואם מוצאים אפליקציות דומות ניתן לבדוק את החוזקות והחולשות שלהן כדי לפתח תוכנה חדשה ואיכותית יותר. לאחר מחקר השוק הקפדני יש להגדיר מטרות מדויקות וברות השגה.`}
      </>,
      <>
        <strong>2. אפיון טכנולוגי של התוכנה</strong>
        {` – בשלב זה צוות המומחים של בית התוכנה בוחר את הטכנולוגיות הנכונות והמתאימות ביותר לפיתוח המוצר, לדוגמא `}
        <Link to={URL.FRONT_END_SERVICE} active>פיתוח Front End</Link>
        {` או `}
        <Link to={URL.FULL_STACK_SERVICE} active>פיתוח Full Stack מקצה לקצה</Link>,
        {` `}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>אפליקציית Saas</Link>
        {` וכו׳. `}
        {`מעבר לבחירת הטכנולוגיות בשלב זה מאפיינים את שלד התוכנה, תזרים נתונים, סדר הפיתוח של רכיבי המערכת, רשימת מודולים שיש לפתח וקובעים לוח זמנים מדורג. בנוסף, יש להתייחס לנושא התממשקות למערכות קיימות.`}
      </>,
      <>
        <strong>3. אפיון UI/UX</strong>
        {` – שלב קריטי באפיון תוכנה חדשה, צוות המומחים מחליט בתיאום עם הלקוח כיצד המסכים ייראו, לאיזה מידע ואפשרויות המשתמש הסופי ייחשף, איך המשתמש ינווט את דרכו במערכת, היררכיה של הפיצ'רים השונים במערכת ועוד.`}
      </>,
      <>
        <strong>4. אסטרטגיית שיווק</strong>
        {` – מסמך אפיון מקצועי כולל בין היתר התייחסות לאסטרטגיית השיווק והמכירות של המוצר הטכנולוגי, לכן בעזרת הלקוח מגדירים מסר שיווקי עיקרי וצוות הפיתוח ועיצוב הממשקים נדרש ליצירתיות ולשלב פתרונות יעילים שיגרמו ליוזרים להמליץ על התוכנה לאחרים, לנסות את התוכנה ולהטמיע אותה בארגון שלהם.`}
      </>,
    ],
  },
  section5: {
    h: "כיצד תהליך האפיון משפיע על פיתוח ובניית התוכנה?",
    p: [
      "תהליך אפיון תוכנה מגדיר את המטרות, הטכנולוגיות והרכיבים הגרפיים במערכת. יש שתי גישות עיקריות להכנת מסמך אפיון, מסמך המתחיל במסך הבית של התוכנה ומפרט את המסכים היוצאים ממנו, גישה זו נקראת user flow והיא לינארית ומתייחסת היטב למאפייני המשתמשים הפוטנציאליים. בשיטה זו משרטטים את המסכים ויוצרים תרשים זרימה המקשר ביניהם, והמסמך כולל התייחסות לעיצוב ממשקים, כולל כפתורים, תפריטים, טפסים ועוד. ",
      "הגישה השנייה נקראת Epicentre design, כלומר מתחילים באפיון התוכנה מהפונקציונליות הראשית, כלומר מבסיס ומשלד התוכנה לנדבכים נוספים הקיימים בה. בשיטה זו מגדירים את שלד התוכנה ללא עיצוב מפורט של מסכי המערכת, וקובעים את תרשים הזרימה למעבר בין רכיבי התוכנה והמסכים שבה.",
      "תהליך האפיון מונע בעיות במהלך פיתוח התוכנה, מאחר ומבצעים מחקר שוק ובוחרים טכנולוגיות לאחר בדיקות מעמיקות. אפיון נכון של התוכנה משפיע על הצלחת הפרויקט, ומאפשר לבית התוכנה לפתח מערכת ללא באגים ובהתאמה מושלמת לצרכי הלקוח ולמאפייני המשתמשים הפוטנציאליים, ללא חריגות בלוח הזמנים ובתקציב.",
      <>
        {`צוות חברת אפספורס מחויב ללקוחות ופועל כמו ביחידה צבאית מאורגנת היטב, אנו `}
        <Link to={URL.HOME} active>מתמחים בפיתוח תוכנות ואפליקציות</Link>
        {` באמצעות טכנולוגיות מתקדמות ושפות פיתוח עדכניות כמו פרונט אנד, וכדי להבטיח לכם תוכנה מתקדמת ומוצלחת אנו מתחילים את הפרויקט במסמך אפיון מפורט ומסודר, כאשר אתם לוקחים חלק פעיל באפיון התוכנה ומטרותיה.`}
      </>,
    ]
  }
};