import * as React from "react";
import { graphql } from 'gatsby';
import Blog, {
  BlogSubHeader, BlogList, BlogListItem, BlogUnorderList, BlogUnorderListItem
} from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/how_to_do_software_design_right';
import { HowToDoSoftwareDesignRight, BenefitsOfWorkingWithSoftwareCompany, StagesOnAgileDevelopment, ReactVsAngular } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: "How to do software design right",
  description: "SEO description how to do software design right",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fhow-to-do-software-design-right.jpg?alt=media&token=eff7b5a2-7d5a-485c-9190-fc606c026a9a",
  datePublished: '2022-02-08'
};

const HowToDoSoftwareDesignRightBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("How to do software design right", BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={HowToDoSoftwareDesignRight.url}
      banner="/how-to-do-software-design-right/banner.jpg"
      relatedBlogs={[BenefitsOfWorkingWithSoftwareCompany, StagesOnAgileDevelopment, ReactVsAngular]}
      title="How to do software design right"
      seo={{
        title: 'SEO title how to do software design right',
        description: 'SEO description how to do software design right',
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fhow-to-do-software-design-right.jpg?alt=media&token=eff7b5a2-7d5a-485c-9190-fc606c026a9a',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}


      <Paragraph>
        <BlogUnorderList>
          {HE.section1.ul.map(p => (
            <BlogUnorderListItem>
              {p}
            </BlogUnorderListItem>
          ))}
        </BlogUnorderList>
      </Paragraph>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section4.h}
      </BlogSubHeader>
      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogList>
        {HE.section4.ol.map(p => (
          <BlogListItem>
            <Paragraph>{p}</Paragraph>
          </BlogListItem>
        ))}
      </BlogList>

      <BlogSubHeader>
        {HE.section5.h}
      </BlogSubHeader>
      {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}

    </Blog>
  )
}

export default HowToDoSoftwareDesignRightBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;